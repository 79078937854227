import React from 'react'
import ComB from './ComB'

const ComA = () => {
    return (
        <>
            <ComB />
        </>
    )
}

export default ComA