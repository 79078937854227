import React from 'react'
import ComC from './ComC'

const ComB = () => {
    return (
        <>
            <ComC />
        </>
    )
}

export default ComB