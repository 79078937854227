import React from 'react'
import ComD from './ComD'

const ComC = () => {
    return (
        <>
            <ComD />
        </>
    )
}

export default ComC