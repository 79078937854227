
import './App.css';
import MyRoute from './MyRoute';


function App() {
  return (
    <>
    <MyRoute/>
    </>
  );
}

export default App;
